  <template>

  <travio-center-container grid-width='1/2'>
    <h2 class="mb-4" style="color:#636363">{{formTitle}}</h2>
    <vx-card>
      <div class="flex flex-wrap items-center mb-2">
        <vs-avatar 
          :src="form.groupImageUrl"
          icon="cloud_upload"
          @click="$refs.groupImageInput.click()"
          size="70px"
          class="mr-4 mb-4 company-logo-avatar" />
        <div>
          <input type="file" hidden id="groupImageInput" ref="groupImageInput" v-on:change="handleFileUpload"/>
          <vs-button @click="$refs.groupImageInput.click()" class="mr-4 sm:mb-0 mb-2">{{ uploadImageButtonText }}</vs-button>
          <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Group Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.groupName" v-validate="'required|max:100'" name="Group Name" />
          <span class="text-danger text-sm">{{ errors.first('Group Name') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Event Name</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.eventName" v-validate="'max:100'" name="Event Name" />
          <span class="text-danger text-sm">{{ errors.first('Event Name') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Description*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-textarea 
            name="Description"
            rows="8" 
            v-model="form.description"
            v-validate="'required|max:500'"
            class="w-full mb-0"/>
          <span class="text-danger text-sm">{{ errors.first('Description') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Event Location</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="flex flex-wrap justify-start">
            <vs-button v-if="!locationStateForEditor.name" color="primary" @click="showLocationAutoComplete=true" size="small" type="filled">Set Location</vs-button>      
            <div title="Click to edit location"><a href="#" v-if="locationStateForEditor" @click="showLocationAutoComplete=true" class="hover:underline">{{ locationStateForEditor.name }}</a></div>
          </div>
          
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Active</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-switch
            class=""
            v-model="form.isActive"
          />
        </div>
      </div>

      

      <div class="flex flex-wrap justify-end">
          <vs-button class="" color="danger" @click="onCancel" type="filled">Cancel</vs-button>      
          <vs-button class="ml-2" color="primary" @click="onSave" type="filled">Save</vs-button>      
      </div>
    </vx-card>

    <location-editor-auto-complete 
      v-if="showLocationAutoComplete"
      :applicationId="applicationId"
      :location="locationStateForAutoComplete"
      :noSave="true"
      @onEditSuccess="onEditSuccessAutoComplete"
      @onCancel="showLocationAutoComplete=false" 
    />
  </travio-center-container>
</template>

<script>
import { Form } from '@/core-modules/form-framework/Form.js'
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';
import LocationEditorAutoComplete from '../location-lists/components/LocationEditorAutoComplete.vue'

export default {
  components: {
    LocationEditorAutoComplete,
  },
  props: {
    applicationId: { required: true },
    groupId: { required: false, default: 0 },
  },
  data () {
    return {
      form: new Form({
        groupId: 0,
        groupName: null,
        eventName: null,
        description: '',
        isActive: true,
        groupImageUrl: null,
        groupLocation: null
      }),
      groupImage: null,
      showLocationAutoComplete: false,
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    formTitle() {
      return this.groupId == 0 ? 'Add Group' : 'Edit Group'
    },
    locationStateForEditor () {
      return this.form.groupLocation ? JSON.parse(this.form.groupLocation) : {}
    },
    locationStateForAutoComplete () {
      return this.form.groupLocation ? { jsonData: this.form.groupLocation } : { jsonData: null }
    },
    uploadImageButtonText () {
      if(this.form.groupImageUrl) {
        return 'Update Group Image'
      } else {
        return 'Upload Group Image'
      }
    }
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    if (!this.appHasFeature('customextras')) {
      this.$router.push('/error-404')
    }

    try {
      if (this.groupId) {
        this.$vs.loading()
        const response = await this.$http.get(`api/customextras/apps/${this.applicationId}/groups/${this.groupId}`)
        this.form = Object.assign(this.form, response.data)
        this.$vs.loading.close();
      } 

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();

  },
  mounted() {

  },
  methods: {
    async onSave (){

      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      this.$vs.loading()
      if (this.groupId) {
        try {
          await this.form.put(`api/customextras/apps/${this.applicationId}/groups`)
          this.$_notifySuccess('Successfully updated group');
        } catch (error) {
          this.$_notifyFailure(error)
        }
        
      } else {
        try {
          var response = await this.form.post(`api/customextras/apps/${this.applicationId}/groups`)
          if(this.groupImage && response.data) {
            this.submitGroupImage(response.data)
          }
          this.$_notifySuccess('Successfully created group');
        } catch (error) {
          this.$_notifyFailure(error)
        }
      }
      this.$vs.loading.close();
      this.onCancel()

      
    },
    
    async handleFileUpload () {
      this.groupImage = this.$refs.groupImageInput.files[0];
      if (this.groupImage && !this.groupId) {
        // Temporary render file, can't upload yet since Application ID is not yet available
        this.form.groupImageUrl = URL.createObjectURL(this.groupImage)
      }
      else if (this.groupImage && this.groupId) {
        this.$vs.loading()
        try {
          await this.submitGroupImage(this.groupId)
          this.$_notifySuccess('Successfully uploaded group image')
        } catch (error) {
          this.$_notifyFailure('Unable to upload group image, you may go to application settings and try again.')
        } finally {
          this.$vs.loading.close()
        }
      } else {
        this.form.groupImageUrl = null
      }
    },
    async submitGroupImage (groupId) {
      let formData = new FormData();
      formData.append('groupImage', this.groupImage)
      try {
        const response = await this.$http.post(`api/customextras/apps/${this.applicationId}/groups/${groupId}/images`, formData, {
            headers: { 'Content-Type': 'multipart/form-data'}
        })
        this.form.groupImageUrl = response.data
        return response
      } catch (error) {
        throw error
      }
    },
    removeGroupImage () {
      this.form.groupImageUrl = null
      this.groupImage = null
    },
    onEditSuccessAutoComplete (locationState) {
      this.form.groupLocation = locationState
      this.showLocationAutoComplete = false
    },
    onCancel () {
      if( this.groupId ) {
        this.$router.push({name: 'application-tools-extra-groups-extra-list', 
          params: { applicationId: this.applicationId, groupId: this.groupId }
        })
      } else {
        this.$router.push({name: 'application-tools-extra-groups', 
          params: { applicationId: this.applicationId }
        })
      }
    },
  }
}
</script>

<style>

</style>